.form {
    width: 560px;
    box-shadow: 1px 2px 10px #c7e4f9cc;
    border-radius: 10px;
    padding: 20px;
    
}

@media only screen and (max-width: 580px)  {
    .form {
        width: 320px;
    }
}

.alert-container {
    background-color: forestgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 20px;
}

.alert-text {
    color: #fff;
    font-weight: 600;
}